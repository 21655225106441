import React, { useState } from "react";
import { useGlobal } from "reactn";
import SEO from "../components/seo";
import BackgroundImage from "gatsby-background-image";
import * as Styled from "../css/newsStyle";
import { Link } from "gatsby";
import App from "./app";
import  * as Global from '../css/globalStyle';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleRight } from "@fortawesome/free-solid-svg-icons";
import FirstThreeNews from "../components/firstThreeNews";
import getLang from "../components/scripts/getLang";

import { NewsQuery } from "../components/scripts/graphQlQuerys/newsQuery"

const News = props => {
  let [english] = useGlobal("English");
  const [isLoading, setLoading] = useState(false);

  let news = getLang(english, NewsQuery());
  news = news.sort((a, b) => {
    return (
      (a.node.nyhetsDatum === null) - (b.node.nyhetsDatum === null) ||
      -(a.node.nyhetsDatum > b.node.nyhetsDatum) ||
      +(a.node.nyhetsDatum < b.node.nyhetsDatum)
    );
  });

  let underNews = [...news.slice(3, 6)];
  let restOfNews = underNews.map((item, index) => {
    return (
      <Link
        key={index}
        className="textDecorationNone"
        to={`/news/${item.node.slug}`}
      >
        <BackgroundImage
          Tag="div"
          className={"underNews"}
          fluid={item.node.huvudBild.fluid}
          backgroundColor={`#040e18`}
          key={index}
        >
          <div>
            <h4 style={{display:'grid'}} className="hover">{item.node.rubrik} <span style={{fontSize:'15px'}}>{item.node.nyhetsDatum.replace(/-/g, '.')}</span> </h4>
            
          </div>
        </BackgroundImage>
      </Link>
    );
  });
  const onClick = () => setLoading(true);
  return (
    <App>
      <SEO title={english ? "News" : "Nyheter"} />

      {/* Here you can update SEO */}
      {/* <SEO description={english ? "All news from Stureplan " : "Alla Nyheter från Stureplan"} title={english ? "All News" : "Alla Nyheter"} /> */}

      <FirstThreeNews english={english} page="news" />
      <Styled.RestOfNewsFlex>{restOfNews}</Styled.RestOfNewsFlex>

      {isLoading ? (
        <Styled.AllNewsText>
          <Global.LoadingDots>
            <div className="loading-dots"></div>
            <div className="loading-dots"></div>
            <div className="loading-dots"></div>
          </Global.LoadingDots>
        </Styled.AllNewsText>
      ) : (
        <Styled.AllNewsText>
          <Link onClick={onClick} className="textDecorationNone" to="/newsall">
            <div>
              <p>{english ? "SEE ALL NEWS" : "SE ALLA NYHETER"}</p>
              <FontAwesomeIcon icon={faArrowCircleRight} />
            </div>
          </Link>
        </Styled.AllNewsText>
      )}
    </App>
  );
};

export default News;
